import { cva, type VariantProps } from 'class-variance-authority';
export const badgeVariants = cva(
	/*tw*/ 'inline-flex h-fit text-nowrap items-center font-semibold [&_svg]:h-lg [&_svg]:w-lg',
	{
		variants: {
			border: {
				light: /*tw*/ 'border',
				heavy: /*tw*/ 'border-[1.5px]',
				none: /*tw*/ '',
			},
			rounded: {
				lg: /*tw*/ 'rounded-large',
				full: /*tw*/ 'rounded-small',
				sm: /*tw*/ 'rounded',
				'x-full': /*tw*/ 'rounded-3xl',
				'2xl': /*tw*/ 'rounded-2xl',
			},
			size: {
				xxs: /*tw*/ 'py-none px-xs gap-xxs text-xxs',
				xs: /*tw*/ 'py-xxs px-xs gap-xxs text-xxs',
				sm: /*tw*/ 'py-xxs px-md gap-xs text-xs',
				md: /*tw*/ 'py-xxs px-lg gap-xs text-sm',
				lg: /*tw*/ 'py-xs px-lg gap-xs text-sm',
			},
			color: {
				gray: /*tw*/ 'bg-gray-50 text-gray-700 border-gray-200 [&_svg]:fill-gray-200',
				blue: /*tw*/ 'bg-blue-50 text-blue-700 border-blue-200 [&_svg]:fill-blue-200',
				error:
					/*tw*/ 'bg-error-50 text-error-700 border-error-200 [&_svg]:fill-error-200',
				success:
					/*tw*/ 'bg-success-50 text-success-700 border-success-200 [&_svg]:fill-success-200',
				warning:
					/*tw*/ 'bg-warning-50 text-warning-700 border-warning-200 [&_svg]:fill-warning-200',
				'gray-blue':
					/*tw*/ 'bg-gray-blue-50 text-gray-blue-700 border-gray-blue-200 [&_svg]:fill-gray-blue-200',
				'blue-light':
					/*tw*/ 'bg-blue-light-50 text-blue-light-700 border-blue-light-200 [&_svg]:fill-blue-light-200',
				indigo:
					/*tw*/ 'bg-indigo-50 text-indigo-700 border-indigo-200 [&_svg]:fill-indigo-200',
				purple:
					/*tw*/ 'bg-purple-50 text-purple-700 border-purple-200 [&_svg]:fill-purple-200',
				pink: /*tw*/ 'bg-pink-50 text-pink-700 border-pink-200 [&_svg]:fill-pink-200',
				orange:
					/*tw*/ 'bg-orange-50 text-orange-700 border-orange-200 [&_svg]:fill-orange-200',
				teal: /*tw*/ 'bg-teal-50 text-teal-600 border-teal-200 [&_svg]:fill-teal-600',
				emerald:
					/*tw*/ 'bg-emerald-50 text-emerald-700 border-emerald-200 [&_svg]:fill-teal-600',
				white:
					/*tw*/ 'bg-white border-gray-300 text-gray-700  [&_svg]:fill-gray-200',
			},
		},
		defaultVariants: {
			border: 'light',
			rounded: 'full',
			size: 'xs',
			color: 'gray',
		},
	},
);

export type BadgeVariants = VariantProps<typeof badgeVariants>;
export { default as Badge } from './Badge.vue';
