<script setup lang="ts">
import { type HTMLAttributes } from 'vue';
import { cn } from '@laam/lib/utils';
import { badgeVariants, type BadgeVariants } from '.';

const props = defineProps<{
	border?: BadgeVariants['border'];
	size?: BadgeVariants['size'];
	rounded?: BadgeVariants['rounded'];
	color?: BadgeVariants['color'];
	class?: HTMLAttributes['class'];
}>();
</script>

<template>
	<div
		:class="
			cn(
				badgeVariants({
					border,
					size,
					rounded,
					color,
				}),
				props.class,
			)
		"
	>
		<slot name="leftIcon" />
		<slot name="default" />

		<slot name="rightIcon" />
	</div>
</template>
